@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=REM&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tilt+Prism&display=swap");

* {
  -webkit-tap-highlight-color: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
